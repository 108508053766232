import EditorModal from 'components/admin-bridge/EditorModal';
import { GridTextAlignmentOptions } from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment/types';
import EditableArea from 'components/admin2/ui/EditableArea';
import { ADMIN_SURFACE_4 } from 'style/constants';
import styled, { css } from 'styled-components';

export const ImageCard = styled(EditableArea).attrs({
  borderStyle: 'dashed',
})<{
  hasLink: boolean;
  inEditMode: boolean;
}>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  ${({ inEditMode }) => inEditMode && css`
    border-color: ${ADMIN_SURFACE_4};
  `};
  cursor: ${({ hasLink }) => hasLink ? 'pointer' : 'default'};
  border-radius: 5px;
  height: 100%;
`;

export const LinkModal = styled(EditorModal).attrs({
  itemSpacing: '20px',
  modalTitleKey: 'LINK',
})``;

export const GRADIENT_CSS_MAP: Record<GridTextAlignmentOptions, ReturnType<typeof css>> = {
  topLeft: css`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 78.53%), transparent;
  `,
  topCenter: css`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 78.53%), transparent;
  `,
  topRight: css`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 78.53%), transparent;
  `,
  centerLeft: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, rgba(0, 0, 0, 0.7) -1.99%, rgba(0, 0, 0, 0) 100%), transparent;
  `,
  center: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), transparent;
  `,
  centerRight: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(270deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 96.62%), transparent;
  `,
  bottomLeft: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0.05%, rgba(0, 0, 0, 0) 70.52%), transparent;
  `,
  bottomCenter: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0.05%, rgba(0, 0, 0, 0) 70.52%), transparent;
  `,
  bottomRight: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0.05%, rgba(0, 0, 0, 0) 70.52%), transparent;
  `,
};

export const ContentGradient = styled.div<{ gridTextAlignment: GridTextAlignmentOptions; }>`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  position: absolute;

  ${({ gridTextAlignment }) => GRADIENT_CSS_MAP[gridTextAlignment]};
`;
