import React from 'react';

const useLinkModal = () => {
  const imageCardRef = React.useRef<HTMLDivElement>(null);
  const [linkModalOpen, setLinkModalOpen] = React.useState(false);
  const [container, setContainer] = React.useState<null | {
    rect: { height: number; width: number };
    x: number;
    y: number;
  }>(null);

  const toggleLinkModal = () => {
    setLinkModalOpen(open => !open);
    const { current: imageCard } = imageCardRef;
    const imageCardRect = imageCard?.getBoundingClientRect();
    const currentPosition = imageCardRect ? {
      x: imageCardRect.left,
      y: imageCardRect.top,
      rect: { height: imageCardRect.height, width: imageCardRect.width },
    } : { x: 0, y: 0, rect: { height: 0, width: 0 } };
    setContainer(currentPosition);
  };

  return {
    toggleLinkModal,
    imageCardRef,
    linkModalOpen,
    setLinkModalOpen,
    container,
  };
};

export default useLinkModal;
