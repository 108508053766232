import React from 'react';
import { CallToActionsWrapper, Description, Title, StyledQuickEditOverlay, CTAEditorModal, StyledCTAButton } from './styles';
import { IHeroBanner, IHeroBannerProps } from './utils';
import BlockSettings from './BlockSettings';
import { isEditMode } from 'services/admin';
import { useSelector } from 'react-redux';
import BackgroundImage from './Background';
import { isMobileLayout } from 'services/device';
import usePageBlock from 'hooks/use-page-block';
import useHeroBanner from './use-hero-banner';
import { getAdminBarWidth } from 'services/admin/selectors';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

type EditableStringPath = (
  'title.rawData' |
  'title.rawDataV2' |
  'description.rawData' |
  'description.rawDataV2' |
  'primaryCta.text' |
  'secondaryCta.text' |
  'primaryCta.behavior' |
  'secondaryCta.behavior' |
  'primaryCta.fontSize' |
  'secondaryCta.fontSize' |
  'primaryCta.variant' |
  'secondaryCta.variant' |
  'primaryCta.height.desktop' |
  'primaryCta.height.mobile' |
  'secondaryCta.height.desktop' |
  'secondaryCta.height.mobile'
);

const HeroBanner: React.FC<IHeroBannerProps> = ({ item, onEdit, onRemove, onMoveDown, onMoveUp, disableImageBleed }) => {
  const isEditing = useSelector(isEditMode);
  const isMobile = useSelector(isMobileLayout);
  const adminBarWidth = useSelector(getAdminBarWidth);
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const useTitleRawDataV2 = Boolean(isRichTextEditorV3Enabled && item.data.title.rawDataV2);
  const useDescriptionRawDataV2 = Boolean(isRichTextEditorV3Enabled && item.data.description.rawDataV2);

  const shouldTranslate = !isEditing;
  const {
    background,
    backgroundImageHeight,
    setBackgroundImageHeight,
    backgroundHref,
    hasBackgroundImage,
    hasContentOnHeroBanner,
    title,
    description,
    primaryCtaText,
    secondaryCtaText,
    backgroundHrefTarget,
  } = useHeroBanner({
    heroBannerItem: item,
    disableImageBleed,
  });

  const handleChangeData = React.useCallback((update: IHeroBanner) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPath, IHeroBanner>({
    item: item.data,
    onChange: handleChangeData,
  });

  const renderTitle = React.useCallback(() => {
    if (!item.data.title.show) {
      return null;
    }
    return (
      <Title
        blockTitleKey="ADMIN_LABEL_TITLE"
        onChange={handleChange(useTitleRawDataV2 ? 'title.rawDataV2' : 'title.rawData')}
        data={title}
        useRawDataV2={useTitleRawDataV2}
        maxLength={50}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-testid="heroBannerTitle"
        htmlTag="h1"
      />
    );
  }, [item.data.title.show, title, handleChange]);

  const renderDescription = React.useCallback(() => {
    if (!item.data.description.show) {
      return null;
    }

    return (
      <Description
        blockTitleKey="ADMIN_LABEL_DESCRIPTION"
        onChange={handleChange(useDescriptionRawDataV2 ? 'description.rawDataV2' : 'description.rawData')}
        useRawDataV2={useDescriptionRawDataV2}
        data={description}
        maxLength={500}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
        data-testid="heroBannerDescription"
      />
    );
  }, [item.data.description.show, description, handleChange]);

  const renderPrimaryCta = React.useCallback(() => {
    if (!item.data.primaryCta.show) {
      return null;
    }

    const cta = {
      ...item.data.primaryCta,
      text: shouldTranslate ? primaryCtaText : item.data.primaryCta.text,
    };

    return (
      <CTAEditorModal
        modalTitleKey="ADMIN_LABEL_PRIMARY_CALL_TO_ACTION_(CTA)"
        blockTitleKey="ADMIN_LABEL_PRIMARY_CALL_TO_ACTION"
        onChangeFontSize={handleChange('primaryCta.fontSize')}
        onChangeVariant={handleChange('primaryCta.variant')}
        onChangeBehavior={handleChange('primaryCta.behavior')}
        onChangeText={handleChange('primaryCta.text')}
        onChangeDesktopHeight={handleChange('primaryCta.height.desktop')}
        onChangeMobileHeight={handleChange('primaryCta.height.mobile')}
        cta={cta}
      >
        {(text) => (
          <StyledCTAButton
            fontSize={cta.fontSize}
            variant={cta.variant}
            height={cta.height}
          >
            {text}
          </StyledCTAButton>
        )}
      </CTAEditorModal>
    );
  }, [item.data.primaryCta, shouldTranslate, primaryCtaText, isEditing, handleChange, adminBarWidth]);

  const renderSecondaryCta = React.useCallback(() => {
    if (!item.data.secondaryCta.show) {
      return null;
    }

    const cta = {
      ...item.data.secondaryCta,
      text: shouldTranslate ? secondaryCtaText : item.data.secondaryCta.text,
    };

    return (
      <CTAEditorModal
        modalTitleKey="ADMIN_LABEL_SECONDARY_CALL_TO_ACTION_(CTA)"
        blockTitleKey="ADMIN_LABEL_SECONDARY_CALL_TO_ACTION"
        onChangeFontSize={handleChange('secondaryCta.fontSize')}
        onChangeVariant={handleChange('secondaryCta.variant')}
        onChangeBehavior={handleChange(('secondaryCta.behavior'))}
        onChangeText={handleChange('secondaryCta.text')}
        onChangeDesktopHeight={handleChange('secondaryCta.height.desktop')}
        onChangeMobileHeight={handleChange('secondaryCta.height.mobile')}
        cta={cta}
      >
        {(text) => (
          <StyledCTAButton
            fontSize={cta.fontSize}
            variant={cta.variant}
            height={cta.height}
          >
            {text}
          </StyledCTAButton>
        )}
      </CTAEditorModal>
    );
  }, [item.data.secondaryCta, shouldTranslate, secondaryCtaText, isEditing, handleChange, adminBarWidth]);

  return (
    <StyledQuickEditOverlay
      bleed={background.bleed}
      imageHeight={backgroundImageHeight}
      hasBackgroundImage={hasBackgroundImage}
      testId={`hero-banner-${item.arrayId}`}
      titleKey="LABEL_HERO_BANNER"
      onEdit={handleOpenSettings}
      onRemove={onRemove}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
    >
      <BackgroundImage
        hasContentOnHeroBanner={hasContentOnHeroBanner}
        background={background}
        target={backgroundHrefTarget}
        isMobile={isMobile}
        href={backgroundHref}
        isEditing={isEditing}
        onImageHeightChange={setBackgroundImageHeight}
      />
      {renderTitle()}
      {renderDescription()}
      <CallToActionsWrapper>
        {renderPrimaryCta()}
        {renderSecondaryCta()}
      </CallToActionsWrapper>
      <BlockSettings
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        item={item.data}
        onChange={handleChangeData}
      />
    </StyledQuickEditOverlay>
  );
};

export default HeroBanner;
