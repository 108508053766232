import styled, { css } from 'styled-components';
import { FONT_FAMILY_BODY, FONT_FAMILY_TITLE, TEXT_100, TEXT_200 } from 'style/constants';
import { desktopOnly, mobileOnly } from 'style/mixins';
import { TEXT_BODY_M_REGULAR, TEXT_BODY_S_REGULAR, TEXT_BODY_XS_REGULAR, TEXT_LABEL_L_MEDIUM, TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_TITLE_L, TEXT_TITLE_M, TEXT_TITLE_S } from 'style/design-system/textStyles';
import { IImageGallery } from '../../utils';
import { GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_COLUMN_CSS, GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_ROW_CSS, TEXT_ALIGNMENT_CSS } from 'components/admin-bridge/EditorModal/ModalComponents/GridTextAlignment/styles';
import InlineTextEditor from 'components/admin2/ui/InlineTextEditor';
import CTAButtonEditorModal from 'components/admin-bridge/CTAEditorModal';
import { CTAButton } from 'components/ui/CTAButton';

export const CTAEditorModal = styled(CTAButtonEditorModal)`
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  margin-top: 10px;
`;

export const StyledCTAButton = styled(CTAButton)<{ imagesPerRow: IImageGallery['imagesPerRow']; }>`
  max-width: 100%;
  ${({ imagesPerRow }) => imagesPerRow <= 4 ? desktopOnly`${TEXT_LABEL_M_BOLD}` : desktopOnly`${TEXT_LABEL_S_BOLD}`};
  ${mobileOnly`${TEXT_LABEL_S_BOLD}`};
`;


export const Label = styled(InlineTextEditor)<{ imagesPerRow: number; }>`
  width: 100%;
  & .mce-content-body {
    color: ${TEXT_100};

    & * {
      font-family: ${FONT_FAMILY_TITLE};
    }

    ${({ imagesPerRow }) => {
      if (imagesPerRow <= 2) {
        return desktopOnly`
          ${TEXT_LABEL_L_MEDIUM}
          font-weight: unset;
        `;
      }

      if (imagesPerRow <= 4) {
        return desktopOnly`
          ${TEXT_LABEL_M_MEDIUM}
          font-weight: unset;
        `;
      }

      return desktopOnly`
        ${TEXT_LABEL_S_MEDIUM}
        font-weight: unset;
      `;
    }};

    ${mobileOnly`
      ${TEXT_LABEL_S_MEDIUM}
      font-size: 0.75rem !important;
    `};
  }
`;

export const Title = styled(InlineTextEditor)<{ imagesPerRow: number; }>`
  width: 100%;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_TITLE};
    }

    color: ${TEXT_100};
    ${({ imagesPerRow }) => {
      if (imagesPerRow <= 2) {
        return desktopOnly`
          ${TEXT_TITLE_L}
          font-weight: unset;
        `;
      }

      if (imagesPerRow <= 4) {
        return desktopOnly`
          ${TEXT_TITLE_M}
          font-weight: unset;
        `;
      }

      return desktopOnly`
        ${TEXT_TITLE_S}
        font-weight: unset;
      `;
    }};
    ${mobileOnly`
      ${TEXT_TITLE_S}
      font-size: 1rem !important;
    `};
  }
`;

export const Description = styled(InlineTextEditor)<{ imagesPerRow: number; }>`
  width: 100%;
  & .mce-content-body {
    & * {
      font-family: ${FONT_FAMILY_BODY};
    }

    color: ${TEXT_200};
    ${({ imagesPerRow }) => {
      if (imagesPerRow <= 3) {
        return desktopOnly`
          ${TEXT_BODY_M_REGULAR}
          font-weight: unset;
        `;
      }

      if (imagesPerRow <= 4) {
        return desktopOnly`
          ${TEXT_BODY_S_REGULAR}
          font-weight: unset;
        `;
      }

      return desktopOnly`
        ${TEXT_BODY_XS_REGULAR}
        font-weight: unset;
      `;
    }};
    ${mobileOnly`
      ${TEXT_BODY_S_REGULAR}
      font-size: 0.875rem !important;
    `};
  }
`;

export const Wrapper = styled.div<{ alignment: IImageGallery['gridTextAlignment']; imageLayout: IImageGallery['imageLayout']; isFocused: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
  width: 100%;
  height: 100%;

  ${({ imageLayout, isFocused }) => imageLayout === 'contentInside' && css`
    /* z-index to make the elements clickable in front of the image */
    z-index: ${isFocused ? 3 : 2};
    padding: 20px;


    /* This will make the wrapper onclick event not being listened and
      the admin can click on the texts, CTA and also the image behind it
     */
    pointer-events: none;
    & > div {
      pointer-events: all;
    }
  `};

  /* For CTA */
  ${({ alignment }) => GRID_TEXT_ALIGNMENT_FLEX_DIRECTION_COLUMN_CSS[alignment]}

  & .mce-content-body {
    & * {
      ${({ alignment }) => TEXT_ALIGNMENT_CSS(alignment)};
    }
  }
`;
