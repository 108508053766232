import useResizeObserver from 'hooks/use-resize-observer';
import React from 'react';
import DesktopImage from './Desktop';
import MobileImage from './Mobile';
import { IHeroBanner } from '../utils';
import { ClickableArea } from './styles';

interface IHeroBannerBackgroundProps {
  background: Omit<IHeroBanner['background'], 'href'>;
  hasContentOnHeroBanner: boolean;
  href: string;
  isEditing: boolean;
  isMobile: boolean;
  onImageHeightChange?: (height: number) => void;
  target: '_blank' | '_self';
}

const Background: React.FC<IHeroBannerBackgroundProps> = ({ href, target, background, isMobile, isEditing, onImageHeightChange, hasContentOnHeroBanner }) => {
  const [imageRef, { height: imageHeight }] = useResizeObserver();
  const [imageHasError, setImageHasError] = React.useState(false);

  React.useEffect(() => {
    onImageHeightChange?.(imageHeight);
  }, [imageHeight, onImageHeightChange]);

  const handleImageError = () => setImageHasError(true);

  const renderImage = React.useCallback(() => {
    if (isMobile) {
      return <MobileImage {...background} onError={handleImageError} imageRef={imageRef} imageHeight={imageHeight} />;
    }

    return <DesktopImage {...background} onError={handleImageError} imageRef={imageRef} imageHeight={imageHeight} hasContentOnHeroBanner={hasContentOnHeroBanner} />;
  }, [isMobile, background, imageHeight, imageRef]);

  if ((!background.desktop && !isMobile) || (!background.mobile && isMobile) || imageHasError) {
    return null;
  }

  return (
    <>
      {renderImage()}
      {!isEditing && <ClickableArea href={href} target={target} />}
    </>
  );
};

export default Background;
