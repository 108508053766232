import React, { PropsWithChildren } from 'react';
import { LinkInput } from 'components/admin-bridge/EditorModal';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin/selectors';
import Image from './Image';
import { ContentGradient, ImageCard, LinkModal } from './styles';
import useLinkModal from './use-link-modal';
import TextContent from './TextContent';
import usePageBlock from 'hooks/use-page-block';
import Link from 'components/ui/Link';
import { useImageGalleryContext } from '../ImageGalleryContext/ImageGalleryContext';
import { CTAButtonProps } from 'components/admin-bridge/CTAEditorModal/utils';

export interface ImageCardProps {
  cta: CTAButtonProps;
  description: string;
  descriptionV2?: string;
  id: string;
  image: {
    desktop: string;
    mobile: string;
  }
  label: string;
  labelV2?: string;
  link: string;
  title: string;
  titleV2?: string;
}

interface GlobalImageCardProps {
  imageCard: ImageCardProps;
}

interface ImageCardEvents {
  onRemove: (id: string) => void;
}

type EditableStringPath = 'link';

const Wrapper: React.FC<PropsWithChildren<{ href: string; isEditing: boolean; target: '_self' | '_blank'; }>> = ({ href, target, isEditing, children }) => {
  if (href && !isEditing) {
    return <Link href={href} target={target}>{children}</Link>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default function({
  onRemove,
  imageCard,
}: GlobalImageCardProps & ImageCardEvents) {
  const [link, setLink] = React.useState('');
  const isEditing = useSelector(isEditMode);
  const { imageCardRef, toggleLinkModal, setLinkModalOpen, container, linkModalOpen } = useLinkModal();
  const inEditMode = useSelector(isEditMode);
  const { hasTextContent, contentBelow, contentInside, gridTextAlignment, editImageCard } = useImageGalleryContext();

  React.useEffect(() => {
    setLink(imageCard.link);
  }, [imageCard.link]);

  const { handleChange } = usePageBlock<EditableStringPath, ImageCardProps>({
    item: imageCard,
    onChange: editImageCard,
  });

  const closeLinkModal = () => {
    handleChange('link')(link);
    setLinkModalOpen(false);
  };

  const handleRemove = () => {
    onRemove(imageCard.id);
  };

  const target = React.useMemo(() => {
    if (imageCard.cta.behavior.type !== 'openLink') {
      return '_self';
    }

    return imageCard.cta.behavior.openInNewTab ? '_blank' : '_self';
  }, [imageCard.cta.behavior]);

  return (
    <Wrapper href={imageCard.link} isEditing={isEditing} target={target}>
      <ImageCard
        isEditing={isEditing}
        blockTitleKey="ADMIN_LABEL_IMAGE_CARD"
        editorActions={[{
          icon: 'linkOutline',
          onClick: toggleLinkModal,
          tooltipKey: 'ACTION_ADD_LINK',
          useAbsolutePosition: true,
        }, {
          confirmationMessageKey: 'ADMIN_PROMPT_CONFIRM_IMAGE_CARD_DELETION',
          descriptionTextKey: 'ADMIN_LABEL_UNDO_WARNING',
          icon: 'trash',
          onClick: handleRemove,
          tooltipKey: 'ACTION_DELETE_IMAGE_CARD',
          useAbsolutePosition: true,
        }]}
        hasLink={!inEditMode && !!link}
        inEditMode={inEditMode}
        editorWrapperRef={imageCardRef}
        testId={`image-gallery-image-card-${imageCard.id}`}
      >
        <LinkModal
          initialPosition={container ? { x: container.x, y: container.y } : undefined}
          isOpen={linkModalOpen}
          onClose={closeLinkModal}
        >
          <LinkInput value={link} onChange={setLink} />
        </LinkModal>
        <Image imageCard={imageCard}>
          {contentInside && hasTextContent && (
            <ContentGradient gridTextAlignment={gridTextAlignment}>
              <TextContent imageCard={imageCard} />
            </ContentGradient>
          )}
        </Image>
        {contentBelow && <TextContent imageCard={imageCard} />}
      </ImageCard>
    </Wrapper>
  );
}
